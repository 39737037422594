#update-form-container {
  margin-right: 10px;
}

#logo-preview-container {
  margin: 15px 0 15px 15px;
  position: relative;
  height: 110px;
  width: 110px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}

#remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
}

#color-picker-container {
  margin: 15px 15px 15px 0;
  height: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 3px;
  border-radius: 0 4px 4px 0;
}

img.image-preview {
  max-width: 100px;
  max-height: 100px;
}

input#logo-upload {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

label[for="logo-upload"] {
  cursor: pointer;
}

div#form-fields-container {
  display: flex;
  flex-direction: row;
}

div#text-inputs-container {
  display: flex;
  flex-direction: column;
}
