#company-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#company-modules-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#company-top-modules-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

#company-bottom-modules-container {
  display: flex;
  flex-direction: row;
  padding: 0 10px 10px 10px;
  max-height: 500px;
}
