#module-container {
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}

.table-paper {
  height: 100%;
}

#table {

}

#module-content {
  height: 100%;
}

#table-container {
  display: block;
  height: calc(100% - 128px);
  overflow: auto;
}

.title-container {
  flex-grow: 1;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

td {
  z-index: 1;
}

#table-title {
  flex-direction: row;
  display: flex;
  align-items: center;
}
