.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(100vw);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100vw);
}

.ReactModal__Content--after-open {
  right: 0
}
