#navbar {
  max-width: 280px;
  transition: width 150ms ease-in;
  overflow-x: hidden;
  border-radius: 0;
  flex-shrink: 0;
}

#navbar.slideIn {
  width: 280px;

}

#navbar.slideOut {
  width: 0;
}

#navbar-contents {
  width: 280px;
}

#navbar-list {
  height: 100%;
}
