.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  padding: 10px;
  padding-left: 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.item {
  width: 200px;
  background-color: #fafafa;
  border-radius: 4px;
  padding-left: 14px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  line-height: 1.46429em !important;
}
