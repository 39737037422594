#dashboard {
	display: flex;
	align-items: stretch;
	flex-grow: 1;
	height: calc(100% - 64px);
}

#dashboard-router {
	width: 100%;
	overflow: auto;
}

#home-container {
  height: 100%;
  width: 100%;
  padding: 8px;
}

.data-container {
  padding: 8px;
}

.number-display {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.number-display > p:first-child {
  font-size: 12px;
  color: #808080;
}

.number-display > p:last-child {
  font-size: 36px;
  font-weight: bolder;
  line-height: normal;
}
