.menu-button {
  margin-left: -12px !important;
  margin-right: 20px !important;
}

.flex-grow {
  flex-grow: 1;
}

.input-field {
  margin-left: 10px !important;
  margin-right: 10px !important;
  box-sizing: border-box !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.space {
  margin: 10px !important;
}

.space-y {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.loading-container {
  position: relative;
  height: 0;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
}

.changed {
  outline: #FFF176 solid 2px !important;
}

.user-edit-form {
  position: relative;
}

.error-msg {
  position: absolute;
  bottom: 0;
  color: #f44336 !important;
}

#chat-container {
  height: 100%;
  width: 280px;
}

#edit-container {
  flex-grow: 1;
  overflow-x: hidden;
  padding: 8px;
}

#content {
  padding: 5px;
}

#user-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%;
}

#notification-table tr:nth-child(1) {
  background-color: #bcf5bc;
}

#user-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-container {
  overflow-y: scroll;
  overflow-x: hidden;
  /*height: 900px;*/
  flex: 1 1 auto;
}

.fill-container {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.item-container header {
  height: 48px;
  min-height: 48px;
}

.item-container header > div {
  height: 48px;
  min-height: 48px;
}

.item-container header h6 {
  padding: 0;
  font-size: 18px;
}

.accordion-summary-content {
  margin: 0 !important;
}
