#risk-level-module-container {
  flex-grow: 1;
}

.risk-level-body-container{ 
  padding: 10px;
}

.risk-level-body-container>div>label{ 
  margin: 0;
}