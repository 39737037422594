#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#navbar-toggle {
  margin-left: -12px;
  margin-right: 20px;
}

.flex-grow {
  flex-grow: 1;
}
