div > fieldset {
  right: 10px !important;
}

#matches-module-container {
  margin-right: 10px;
  flex-grow: 1;
}

#matches-module-container > div#matches-table {
  height: calc(100% - 141.633px);
  overflow-y: scroll;
}

.low-match {
  width: 180px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding-left: 14px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  line-height: 1.46429em !important;
}
