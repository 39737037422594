#main {
  width: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-width: 400px;
  margin-top: 25px;
}

.avatar {
  background-color: blue;
  margin: 10px;
}

.form {
  width: 100%;
  margin-top: 5px;
}

.submit {
  margin-top: 5px,
}
