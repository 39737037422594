@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tooltip {
    position: relative;
  }

  .tooltip::after {
    background-color: #4351AF;
    border-radius: 5px;
    color: #fff;
    display: none;
    padding: 10px 15px;
    position: absolute;
    text-align: center;
    z-index: 999;
  }

  .tooltip::before {
    background-color: #4351AF;
    content: '';
    display: none;
    position: absolute;
    width: 15px;
    height: 15px;
    z-index: 999;
  }

  .tooltip:hover::after {
    display: block;
  }

  .tooltip:hover::before {
    display: block;
  }

  .tooltip.top::after {
    content: attr(data-content);
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px));
  }

  .tooltip.top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
  }

  .tooltip.bottom::after {
    content: 'bottom';
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 10px));
  }

  .tooltip.bottom::before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
  }

  .tooltip.right::after {
    content: attr(data-content);
    top: -100%;
    right: 0;
    transform: translateX(calc(100% + 10px));
    min-width: 200px;
    font-size: 13px;
  }

  .tooltip.right::before {
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 5px), -50%) rotate(45deg);
  }

  .tooltip.left::after {
    content: 'left';
    top: 0;
    left: 0;
    transform: translateX(calc(-100% - 10px));
  }

  .tooltip.left::before {
    top: 50%;
    left: 0;
    transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
  }
}
