#tags-module-container {
  flex-grow: 1;
}

.tag-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 64px);
}

.tag-table-body {
  overflow: auto;
  height: calc(100%-64px);
}

.tag-table-body-container {
  height: 200px;
  overflow-y: scroll;
  width: 100%;
}
