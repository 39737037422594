#subsidiary-module-container {
  flex-grow: 1;
}

#subsidiary-module-container h6 {
  flex-grow: 1;
}

#subsidiary-table {
  height: calc(100% - 64px);
  overflow-y: scroll;
}
